<template>
  <List
    title="relationshipTypes"
    resource="relationshipTypes"
    :basePath="basePath"
    :fields="fields"
    ref="list"
    track-by="type"
    on-row-click="details"
    data-provider="$raaDataProvider"
    :response-mapper="responseMapper"
  >
  </List>
</template>

<script>
  import ModalNavigation from "@/mixins/ModalNavigation";
  import List from "@/components/auth/list/List";

  export default {
    name: "RelationshipTypesList",
    components: {
      List,
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
    },
    data: function () {
      return {
        fields: [
          {
            name: 'name',
            title: 'name',
          },
          {
            name: 'type',
            title: 'type',
          }
        ],
      }
    },
    methods: {
      responseMapper(resp) {
        return {
          ...resp,
          content: resp,
        }
      }
    }
  }
</script>

<style scoped>

</style>
